<template>
  <div class="verify-container">
    <div class="container">

      <div class="d-flex align-items-center mb-3">
        <img src="@/assets/images/icon.svg" alt="RegAlytics Logo" width="40">
        <h3 class="ms-3">Change Your Password</h3>
      </div>

      <div class="confirm-card">
        <form @submit.prevent="handleSubmit">
          <div class="mb-3">
            <label for="passwordOne" class="form-label">New Password</label>
            <input v-model="userPassword1" id="passwordOne" class="form-control" name="userPassword1" type="password" required />
          </div>
          <div class="mb-3">
            <label for="passwordTwo" class="form-label">Confirm New Password</label>
            <input v-model="userPassword2" id="passwordTwo" class="form-control" name="userPassword2" type="password" required />
          </div>
          <button class="btn btn-green" type="submit">Reset Password</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "ConfirmPasswordReset",

  data() {
    return {
      userPassword1: '',
      userPassword2: '',
      uid: '',
      token: ''
    }
  },

  methods: {
    ...mapActions('accounts', ['resetPassword']),

    handleSubmit() {
      const uid = this.uid;
      const token = this.token;
      const password1 = this.userPassword1;
      const password2 = this.userPassword2;

      if (password1 !== password2) {
        this.$store.dispatch('notifications/pushNotification', {message: "Provided passwords do not match!", type: "danger", autoHide: false});
        return;
      }

      if (uid && token && password1 && password2) {
        this.resetPassword({uid, token, password1, password2});
      }
    }
  },

  // Fetch the account token information and more upon load
  // If the information provided is invalid kick them back to the login
  created() {
    this.uid = this.$route.params.uid;
    this.token = this.$route.params.token;
  }

}
</script>

<style scoped>
.verify-container {
  position: fixed;
  top: 50%;
  width: 100%;
  transform: translateY(-50%);
}

.confirm-card {
  background-color: white;
  border-radius: .5rem;
  box-shadow: 0 4px 25px 0 rgba(0,0,0,.1);
  -webkit-transition: all .3s ease-in-out;
  width: 100%;
  display: block;
  position: relative;
  margin-bottom: 15px;
  padding: 2rem 2rem;
  overflow: hidden;
}
</style>